import React, { useState } from "react";
import CallToActionHeader from "../../CallToActionHeader";
import SectionHeader from "../../SectionHeader";
import ItemButton from "../../ItemButton";
import InviteFriendButton from "./InviteFriendButton";
import clanHeader from "../../../assets/images/clanHeader.png";
import inviteFriend from "../../../assets/images/inviteFriend.png";
import battleFriendButton from "../../../assets/images/battleFriendButton.png";
import battleFriendButtonPressed from "../../../assets/images/battleFriendButtonPressed.png";
import lootIcon from "../../../assets/images/loot.png";
import ELOIcon from "../../../assets/images/ELO.png";
import copyFriendInviteLinkButton from "../../../assets/images/copyFriendInviteLinkButton.png";
import copyFriendInviteLinkButtonPressed from "../../../assets/images/copyFriendInviteLinkButtonPressed.png";
import { formatNumberWithCommas } from "../../../utils/Utils";
import "../../../styles/ClanScreen.css";
import useWindowSize from "../../../utils/WindowSize";

function ClanScreen({
  userInfo,
  onBattleFriendRowPressed,
  onInviteFriendPressed,
  onCopyInviteLinkPressed,
  loadingFriendBattleId,
}) {
  const { width } = useWindowSize();
  const buttonHeight = Math.min(68, (width - 36) / 6); // 36px for padding, divide by 6 for reasonable scaling
  const brutalBlocksFriends = userInfo?.brutalBlocksFriends;
  const hasFriends =
    brutalBlocksFriends != null && brutalBlocksFriends.length > 0;
  const friendCount = hasFriends ? brutalBlocksFriends.length : 0;
  const [isCopyPressed, setIsCopyPressed] = useState(false);

  const handleCopyMouseDown = () => {
    setIsCopyPressed(true);
  };

  const handleCopyMouseUp = () => {
    setIsCopyPressed(false);
  };

  return (
    <div className="clan-screen">
      <CallToActionHeader CTAHeaderIcon={clanHeader} />
      <SectionHeader sectionName={`YOUR CLAN (${friendCount})`} />
      <div className="scrollable-content-wrapper">
        <div className="scrollable-content">
          <div className="section-container">
            {!hasFriends && (
              <ItemButton
                key={"Invite A Friend Item"}
                title={"Invite a friend"}
                onSelected={onInviteFriendPressed}
                icon={inviteFriend}
                iconPressed={inviteFriend}
                iconStyle={{ width: 52, height: 52, marginRight: 7 }}
                itemButtonDetailView={
                  <div className="invite-reward-container">
                    <img src={lootIcon} alt="" className="loot-icon" />
                    <div className="reward-info">
                      {"+1M for you and your friend"}
                    </div>
                  </div>
                }
                style={{ height: 72 }}
              />
            )}
            {hasFriends &&
              brutalBlocksFriends.map((friend, index) => (
                <ItemButton
                  key={`Friend Item ${index}`}
                  title={friend.username}
                  titleStyle={{ marginLeft: 21, fontSize: 16 }}
                  onSelected={() => onBattleFriendRowPressed(friend)}
                  icon={battleFriendButton}
                  iconPressed={battleFriendButtonPressed}
                  iconStyle={{ height: 55, marginRight: 6 }}
                  itemButtonDetailView={
                    <div className="friend-row-detailview-container">
                      <img
                        src={ELOIcon}
                        alt=""
                        className="elo-icon-friend-row-detail"
                      />
                      <div className="friend-elo-amount">
                        {friend.brutalBlocksElo}
                      </div>
                      <img
                        src={lootIcon}
                        alt=""
                        className="loot-icon-friend-row-detail"
                      />
                      <div className="friend-loot-amount">
                        {friend.brutalBlocksLoot
                          ? formatNumberWithCommas(friend.brutalBlocksLoot)
                          : ""}
                      </div>
                    </div>
                  }
                  loading={loadingFriendBattleId === friend.userId}
                  style={{ height: 72 }}
                />
              ))}
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <InviteFriendButton
          onClick={onInviteFriendPressed}
          buttonHeight={buttonHeight}
        />
        <button
          className="copy-link-button"
          onClick={onCopyInviteLinkPressed}
          onTouchStart={handleCopyMouseDown}
          onTouchEnd={handleCopyMouseUp}
          onMouseDown={handleCopyMouseDown}
          onMouseUp={handleCopyMouseUp}
          onMouseLeave={handleCopyMouseUp}
        >
          <img
            src={
              isCopyPressed
                ? copyFriendInviteLinkButtonPressed
                : copyFriendInviteLinkButton
            }
            alt="Copy Link"
            style={{ height: buttonHeight }}
          />
        </button>
      </div>
    </div>
  );
}

export default ClanScreen;
