export const ROUTES = {
  AUTH: "auth",
  MATCH_CONCLUDE: "bb/match/conclude",
  REFRESH_TOKEN: "refresh-token",
  USER: "user",
  USER_FRIENDS: "user/friends",
  CLAIM_DAILY_SPOILS: "claimDailySpoils",
  FRIEND_BATTLE_ROOM: "friendBattleRoom",
  UPGRADE_ARMORY_ITEM: "user/armoryItem/upgrade",
  LEADERBOARD: "leaderboard",
  VERIFY_SOCIAL_QUEST: "verifySocialQuest",
};
