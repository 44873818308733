import { log } from "./Logger.js";

export const formatNumberWithCommas = (input) => {
  // Convert input to a number if it's a string
  const num = typeof input === "string" ? parseFloat(input) : input;

  // Check if the input is a valid number
  if (isNaN(num)) {
    throw new Error("Invalid input: must be a number or a numeric string");
  }

  // Convert to string and add commas
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const detectEnvironment = () => {
  const currentUrl = window.location.href;
  log("CurrentURL: ", currentUrl);
  return currentUrl.includes("devbrutalblocks") ? "dev" : "prod";
};

const formatDecimal = (value) => {
  const str = value.toFixed(2);
  return str.replace(/\.?0+$/, "");
};

export const formatLootNumber = (num) => {
  const trillion = 1000000000000;
  const billion = 1000000000;
  const million = 1000000;
  const thousand = 1000;

  if (num >= trillion) {
    const value = num / trillion;
    return `${formatDecimal(value)}T`;
  }
  if (num >= billion) {
    const value = num / billion;
    return `${formatDecimal(value)}B`;
  }
  if (num >= million) {
    const value = num / million;
    return `${formatDecimal(value)}M`;
  }
  if (num >= thousand) {
    const value = num / thousand;
    return `${formatDecimal(value)}K`;
  }
  return num.toString();
};
