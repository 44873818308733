import React from "react";
import BottomSheet from "./BottomSheet";
import SocialQuestContent from "./SocialQuestContent";
import followButton from "../assets/images/followButton.png";
import followButtonPressed from "../assets/images/followButtonPressed.png";
import xLogo from "../assets/images/xLogo.png";

const FollowXBottomSheet = ({
  isOpen,
  onCancel,
  onFollowX,
  onVerifyX,
  deepLinkDate,
  isLoading,
  hasVerified,
}) => {
  const handleClose = () => {
    onCancel?.();
  };

  return (
    <BottomSheet
      isOpen={isOpen}
      onCancel={handleClose}
      canTapOutToCancel={true}
      hideButtons={true}
      height={340}
    >
      <SocialQuestContent
        deepLinkDate={deepLinkDate}
        onDeepLink={onFollowX}
        onVerify={onVerifyX}
        socialLogo={xLogo}
        headertext={"Follow us on X"}
        deeplinkButtonImage={followButton}
        deeplinkButtonImagePressed={followButtonPressed}
        isLoading={isLoading}
        hasVerified={hasVerified}
      />
    </BottomSheet>
  );
};

export default FollowXBottomSheet;
