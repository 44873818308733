import React, { useState } from "react";
import LoadingSpinner from "./LoadingSpinner";
import verifyButtonDisabled from "../assets/images/verifyButtonDisabled.png";
import verifyButton from "../assets/images/verifyButton.png";
import verifyButtonPressed from "../assets/images/verifyButtonPressed.png";
import "../styles/SocialQuestContent.css";

const SocialQuestContent = ({
  deepLinkDate,
  onDeepLink,
  onVerify,
  socialLogo,
  headertext,
  deeplinkButtonImage,
  deeplinkButtonImagePressed,
  isLoading,
  hasVerified,
}) => {
  const [isVerifyPressed, setIsVerifyPressed] = useState(false);
  const [isDeeplinkPressed, setIsDeeplinkPressed] = useState(false);

  const getVerifyMinutes = (deepLinkDate) => {
    if (!deepLinkDate) return 0;

    const now = Date.now();
    const oneHourAfterDeepLink = deepLinkDate + 60 * 60 * 1000;
    const remainingMilliseconds = oneHourAfterDeepLink - now;
    const remainingMinutes = Math.ceil(remainingMilliseconds / (60 * 1000));

    return remainingMinutes > 0 ? remainingMinutes : -1;
  };

  const hasDeepLinked = deepLinkDate != null;
  const verifyMinutes = getVerifyMinutes(deepLinkDate);

  const handleDeeplinkMouseDown = () => {
    setIsDeeplinkPressed(true);
  };

  const handleDeeplinkMouseUp = () => {
    setIsDeeplinkPressed(false);
  };

  const handleVerifyMouseDown = () => {
    setIsVerifyPressed(true);
  };

  const handleVerifyMouseUp = () => {
    setIsVerifyPressed(false);
  };

  const getVerifyButtonImage = () => {
    if (!hasDeepLinked || verifyMinutes > 0) {
      return verifyButtonDisabled;
    }
    if (isVerifyPressed) {
      return verifyButtonPressed;
    }
    return verifyButton;
  };

  return (
    <div className="social-quest-container">
      <img src={socialLogo} alt="Social Logo" className="social-quest-logo" />
      <div className="social-quest-header-text-container">
        <div className="social-quest-header-text">{headertext}</div>
      </div>
      <div className="social-quest-deeplink-button-container">
        <button
          className="social-quest-deeplink-button clickable"
          onClick={onDeepLink}
          onMouseDown={handleDeeplinkMouseDown}
          onMouseUp={handleDeeplinkMouseUp}
          onMouseLeave={handleDeeplinkMouseUp}
          onTouchStart={handleDeeplinkMouseDown}
          onTouchEnd={handleDeeplinkMouseUp}
          disabled={false}
        >
          <img
            src={
              isDeeplinkPressed
                ? deeplinkButtonImagePressed
                : deeplinkButtonImage
            }
            alt="Join"
            className="deeplink-button-image"
          />
        </button>
      </div>
      <div className="verify-later-text-container">
        <div
          className="verify-later-text"
          style={{ opacity: hasDeepLinked && verifyMinutes > 0 ? 100 : 0 }}
        >
          {"Return in " + verifyMinutes + " minutes to verify"}
        </div>
      </div>
      {!hasVerified && (
        <div className="verify-button-container">
          {!isLoading && (
            <button
              className={`verify-button ${
                hasDeepLinked && verifyMinutes <= 0 && !isLoading
                  ? "clickable"
                  : ""
              }`}
              onClick={onVerify}
              onMouseDown={handleVerifyMouseDown}
              onMouseUp={handleVerifyMouseUp}
              onMouseLeave={handleVerifyMouseUp}
              onTouchStart={handleVerifyMouseDown}
              onTouchEnd={handleVerifyMouseUp}
              disabled={!hasDeepLinked || verifyMinutes > 0 || isLoading}
            >
              <img
                src={getVerifyButtonImage()}
                alt="Verify"
                className="verify-button-image"
              />
            </button>
          )}
          {isLoading && (
            <LoadingSpinner
              style={{
                position: "absolute",
                marginBottom: 4,
                height: 30,
                width: 30,
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SocialQuestContent;
