import React from "react";
import BottomSheet from "./BottomSheet";
import welcomeLogo from "../assets/images/welcomeLogo.png";
import welcomeCurrencyContainer from "../assets/images/welcomeCurrencyContainer.png";
import welcomeBountyBoostContainer from "../assets/images/welcomeBountyBoostContainer.png";
import welcomeEloContainer from "../assets/images/welcomeEloContainer.png";
import welcomeUsernameContainer from "../assets/images/welcomeUsernameContainer.png";
import welcomeMainTextHeader from "../assets/images/welcomeMainTextHeader.png";
import "../styles/WelcomeBottomSheet.css";
import { formatLootNumber } from "../utils/Utils";
import useWindowSize from "../utils/WindowSize";

const WelcomeBottomSheet = ({
  isOpen,
  onCancel,
  logoSrc,
  username,
  loot,
  elo,
  bountyBoost,
}) => {
  const { height } = useWindowSize();
  const handleClose = () => {
    onCancel?.();
  };

  return (
    <BottomSheet
      isOpen={isOpen}
      onCancel={handleClose}
      canTapOutToCancel={true}
      hideButtons={true}
      height={Math.min(500, height - 150)}
    >
      <div className="welcome-container">
        <img
          src={welcomeLogo}
          alt="Brutal Knights Logo"
          className="welcome-logo"
          onClick={handleClose}
        />
        <img
          src={welcomeMainTextHeader}
          alt="Welcome Text"
          className="welcome-main-text-header"
        />
        <div className="user-name-container">
          <div className="user-name-text-container">
            <div className="user-name-text">{username.toUpperCase()}</div>
          </div>
          <img
            src={welcomeUsernameContainer}
            alt="user name container"
            className="user-name-container-image"
          />
        </div>
        <div className="welcome-loot-container">
          <div className="welcome-loot-text-container">
            <div className="welcome-loot-text">{formatLootNumber(loot)}</div>
          </div>
          <img
            src={welcomeCurrencyContainer}
            alt="welcome-loot"
            className="welcome-loot-container-image"
          />
        </div>
        <div className="welcome-elo-container">
          <div className="welcome-elo-text-container">
            <div className="welcome-elo-text">{elo}</div>
          </div>
          <img
            src={welcomeEloContainer}
            alt="welcome-elo"
            className="welcome-elo-container-image"
          />
        </div>
        <div className="welcome-bounty-boost-container">
          <div className="welcome-bounty-boost-text-container">
            <div className="welcome-bounty-boost-text">
              {bountyBoost.toFixed(2)}
            </div>
          </div>
          <img
            src={welcomeBountyBoostContainer}
            alt="welcome-bounty-boost"
            className="welcome-bounty-boost-container-image"
          />
        </div>
      </div>
    </BottomSheet>
  );
};

export default WelcomeBottomSheet;
