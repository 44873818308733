import React, { useState } from "react";
import "../../../styles/InviteFriendButton.css";
import buttonInviteLeft from "../../../assets/images/buttonInviteLeft.png";
import buttonInviteRight from "../../../assets/images/buttonInviteRight.png";
import buttonInviteSliver from "../../../assets/images/buttonInviteSliver.png";
import buttonInviteLeftPressed from "../../../assets/images/buttonInviteLeftPressed.png";
import buttonInviteRightPressed from "../../../assets/images/buttonInviteRightPressed.png";
import buttonInviteSliverPressed from "../../../assets/images/buttonInviteSliverPressed.png";

const InviteFriendButton = ({ onClick, buttonHeight }) => {
  const [isPressed, setIsPressed] = useState(false);

  const handlePress = () => {
    onClick();
  };

  const handleMouseDown = () => {
    setIsPressed(true);
  };

  const handleMouseUp = () => {
    setIsPressed(false);
  };

  const leftImage = isPressed ? buttonInviteLeftPressed : buttonInviteLeft;
  const rightImage = isPressed ? buttonInviteRightPressed : buttonInviteRight;
  const sliverImage = isPressed
    ? buttonInviteSliverPressed
    : buttonInviteSliver;

  return (
    <button
      className="invite-friend-button"
      onClick={handlePress}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <img
        src={leftImage}
        alt="Invite Left"
        className="invite-left"
        style={{ height: buttonHeight }}
      />
      <div className="invite-sliver-container">
        <img
          src={sliverImage}
          alt="Invite Sliver"
          className="invite-sliver"
          style={{ height: buttonHeight }}
        />
      </div>
      <img
        src={rightImage}
        alt="Invite Right"
        className="invite-right"
        style={{ height: buttonHeight }}
      />
    </button>
  );
};

export default InviteFriendButton;
