import React from "react";
import BottomSheet from "./BottomSheet";
import SocialQuestContent from "./SocialQuestContent";
import joinButton from "../assets/images/joinButton.png";
import joinButtonPressed from "../assets/images/joinButtonPressed.png";
import discordLogo from "../assets/images/discordLogo.png";

const JoinDiscordBottomSheet = ({
  isOpen,
  onCancel,
  onJoinDiscord,
  onVerifyDiscord,
  deepLinkDate,
  isLoading,
  hasVerified,
}) => {
  const handleClose = () => {
    onCancel?.();
  };

  return (
    <BottomSheet
      isOpen={isOpen}
      onCancel={handleClose}
      canTapOutToCancel={true}
      hideButtons={true}
      height={340}
    >
      <SocialQuestContent
        deepLinkDate={deepLinkDate}
        onDeepLink={onJoinDiscord}
        onVerify={onVerifyDiscord}
        socialLogo={discordLogo}
        headertext={"Join us on Discord"}
        deeplinkButtonImage={joinButton}
        deeplinkButtonImagePressed={joinButtonPressed}
        isLoading={isLoading}
        hasVerified={hasVerified}
      />
    </BottomSheet>
  );
};

export default JoinDiscordBottomSheet;
