import React from "react";
import "../styles/CallToActionHeader.css";

function CallToActionHeader({ CTAHeaderIcon }) {
  return (
    <div className="call-to-action-header">
      <img src={CTAHeaderIcon} alt="CTA Platform" className="cta-platform" />
    </div>
  );
}

export default CallToActionHeader;
