import React from "react";
import "../../../styles/SectionSelector.css";
import sectionSelectorLeft from "../../../assets/images/armorySectionSelectorLeft.png";
import sectionSelectorCenter from "../../../assets/images/armorySectionSelectorSliver.png";
import sectionSelectorRight from "../../../assets/images/armorySectionSelectorRight.png";
import sectionSelectGlow from "../../../assets/images/armorySectionSelectGlow.png";
import useWindowSize from "../../../utils/WindowSize";

const SectionSelector = ({ sections, selectedSection, onSelectSection }) => {
  const { width } = useWindowSize();
  return (
    <div className="section-selector-container">
      <div className="section-selector">
        <img
          src={sectionSelectorLeft}
          alt=""
          className="section-selector-left"
        />
        <div className="section-selector-middle">
          <img
            src={sectionSelectorCenter}
            alt=""
            className="section-selector-middle-bg"
          />
        </div>
        <img
          src={sectionSelectorRight}
          alt=""
          className="section-selector-right"
        />
      </div>
      <div className="section-buttons">
        {sections.map((section) => (
          <button
            key={section}
            className={`section-button ${
              selectedSection === section ? "selected" : ""
            }`}
            onClick={() => onSelectSection(section)}
            style={{ fontSize: width < 350 ? 13 : 15 }}
          >
            {section}
            {selectedSection === section && (
              <img src={sectionSelectGlow} alt="" className="section-glow" />
            )}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SectionSelector;
