import React, { useState } from "react";
import "../styles/ItemButton.css";
import itemButtonArrow from "../assets/images/itemButtonArrow.png";
import itemButtonArrowPressed from "../assets/images/itemButtonArrowPressed.png";
import { playSoundSelectionPrimary } from "../utils/AudioManager";
import LoadingSpinner from "./LoadingSpinner";

function ItemButton({
  title,
  titleStyle,
  onSelected,
  disabled = false,
  icon = itemButtonArrow,
  iconPressed = itemButtonArrowPressed,
  iconStyle = { width: 56, height: 56 },
  itemButtonDetailView,
  style,
  loading,
}) {
  const [isPressed, setIsPressed] = useState(false);

  const handleClick = () => {
    if (!disabled && !loading && onSelected) {
      playSoundSelectionPrimary && playSoundSelectionPrimary();
      onSelected();
    }
  };

  const handleMouseDown = () => {
    if (!disabled && !loading) {
      setIsPressed(true);
    }
  };

  const handleMouseUp = () => {
    setIsPressed(false);
  };

  const currentIcon = isPressed ? iconPressed : icon;

  return (
    <div
      className={`item-button ${disabled ? "disabled" : ""} ${
        !disabled && !loading ? "clickable" : ""
      }`}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
      style={style}
    >
      <div className="item-button-content-container">
        <div className="item-button-title" style={titleStyle}>
          {title}
        </div>
        {itemButtonDetailView}
      </div>
      {!loading && (
        <img
          src={currentIcon}
          style={iconStyle}
          alt="button icon"
          className="item-button-icon"
        />
      )}
      {loading && (
        <LoadingSpinner
          style={{
            height: 30,
            width: 30,
            marginRight: 40,
          }}
        />
      )}
    </div>
  );
}

export default ItemButton;
