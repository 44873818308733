import React, { useState, useEffect } from "react";
import "../styles/TutorialPopup.css";

import tutorialPopup1 from "../assets/images/tutorialPopup1.png";
import tutorialPopup2 from "../assets/images/tutorialPopup2.png";
import tutorialPopup3 from "../assets/images/tutorialPopup3.png";
import tutorialNextButton from "../assets/images/tutorialNextButton.png";
import tutorialNextButtonPressed from "../assets/images/tutorialNextButtonPressed.png";
import tutorialPrevButton from "../assets/images/tutorialPrevButton.png";
import tutorialPrevButtonPressed from "../assets/images/tutorialPrevButtonPressed.png";
import tutorialPrevButtonDisabled from "../assets/images/tutorialPrevButtonDisabled.png";

const TutorialPopup = ({ isOpen, onClose, onFinishTutorial }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isNextPressed, setIsNextPressed] = useState(false);
  const [isPrevPressed, setIsPrevPressed] = useState(false);
  const [animationClass, setAnimationClass] = useState("");
  const [isVisible, setIsVisible] = useState(isOpen);

  const slides = [tutorialPopup1, tutorialPopup2, tutorialPopup3];

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      const timer = setTimeout(() => {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            setAnimationClass("fade-in");
          });
        });
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      setAnimationClass("fade-out");
      const timer = setTimeout(() => setIsVisible(false), 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const handleNextPress = () => {
    if (currentSlide === slides.length - 1) {
      setAnimationClass("fade-out");
      setTimeout(async () => {
        await onFinishTutorial();
        onClose();
      }, 300);
    } else {
      setCurrentSlide((prev) => prev + 1);
    }
  };

  const handlePrevPress = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prev) => prev - 1);
    }
  };

  const handleNextMouseDown = () => setIsNextPressed(true);
  const handleNextMouseUp = () => setIsNextPressed(false);
  const handlePrevMouseDown = () => setIsPrevPressed(true);
  const handlePrevMouseUp = () => setIsPrevPressed(false);

  if (!isVisible) return null;

  const getPrevButtonImage = () => {
    if (currentSlide === 0) return tutorialPrevButtonDisabled;
    if (isPrevPressed) return tutorialPrevButtonPressed;
    return tutorialPrevButton;
  };

  const getNextButtonImage = () => {
    if (isNextPressed) return tutorialNextButtonPressed;
    return tutorialNextButton;
  };

  return (
    <div className={`tutorial-overlay ${animationClass}`}>
      <div className="tutorial-content">
        <img
          src={slides[currentSlide]}
          alt={`Tutorial slide ${currentSlide + 1}`}
          className="tutorial-slide"
        />
        <div className="tutorial-buttons">
          <button
            className={`tutorial-button prev-button ${
              currentSlide === 0 ? "disabled" : ""
            }`}
            onClick={handlePrevPress}
            onMouseDown={handlePrevMouseDown}
            onMouseUp={handlePrevMouseUp}
            onMouseLeave={handlePrevMouseUp}
            onTouchStart={handlePrevMouseDown}
            onTouchEnd={handlePrevMouseUp}
            disabled={currentSlide === 0}
          >
            <img
              src={getPrevButtonImage()}
              alt="Previous"
              className="button-image"
            />
          </button>
          <button
            className="tutorial-button next-button"
            onClick={handleNextPress}
            onMouseDown={handleNextMouseDown}
            onMouseUp={handleNextMouseUp}
            onMouseLeave={handleNextMouseUp}
            onTouchStart={handleNextMouseDown}
            onTouchEnd={handleNextMouseUp}
          >
            <img
              src={getNextButtonImage()}
              alt="Next"
              className="button-image"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TutorialPopup;
