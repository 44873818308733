import React, { useState } from "react";
import PropTypes from "prop-types";
import "../../../styles/EnterEthAddressBottomSheet.css";
import BottomSheet from "../../BottomSheet";
import TextInput from "../../TextInput";
import InfoCard from "../../InfoCard";
import enterEthAddressTitle from "../../../assets/images/enterEthAddressTitle.png";
import * as ethereum_address from "ethereum-address";
import useWindowSize from "../../../utils/WindowSize";
import { log } from "../../../utils/Logger.js";

function EnterEthAddressBottomSheet({
  onCancel,
  onConfirm,
  ethereumAddress,
  isOpen,
}) {
  const [address, setAddress] = useState(ethereumAddress || "");

  const validateEthereumAddress = (address) => {
    if (
      !address ||
      typeof address !== "string" ||
      address.trim().length === 0
    ) {
      return false;
    }

    log("address: ", address);
    return ethereum_address.isAddress(address);
  };

  const [isAddressValid, setIsAddressValid] = useState(
    ethereumAddress ? validateEthereumAddress(ethereumAddress) : false
  );
  const { width, height } = useWindowSize();

  const handleAddressChange = (e) => {
    const newAddress = e.target.value;
    setAddress(newAddress);
    const isValid = validateEthereumAddress(newAddress);
    setIsAddressValid(isValid);
    log("isAddressValid: ", isValid);
  };

  const handleConfirm = () => {
    if (isAddressValid) {
      onConfirm(address);
    }
  };

  const handleCancel = () => {
    onCancel();
    setTimeout(() => {
      setAddress(ethereumAddress);
      const isValid = validateEthereumAddress(ethereumAddress);
      setIsAddressValid(isValid);
    }, 500);
  };

  return (
    <BottomSheet
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      canTapOutToCancel={false}
      isConfirmEnabled={isAddressValid}
      isOpen={isOpen}
      height={Math.min(500, height)}
    >
      <div className="enter-eth-address-content">
        <img
          src={enterEthAddressTitle}
          alt="Enter Ethereum Address"
          className="enter-eth-address-title"
        />
        <TextInput
          value={address}
          onChange={handleAddressChange}
          placeholder="0x..."
          style={{ color: isAddressValid ? "#ffffff" : "red" }}
        />
        <InfoCard
          text={
            "Ensure this is a valid Ethereum address. Non-Ethereum addresses will not receive the airdrop."
          }
          style={{ marginTop: -9 }}
          textStyle={{ fontSize: width < 356 ? 11 : 12 }}
        />
        <InfoCard
          text={
            "Double-check your address before submitting. Transactions on the blockchain are irreversible."
          }
          style={{ marginTop: -4 }}
          textStyle={{ fontSize: width < 356 ? 11 : 12 }}
        />
      </div>
    </BottomSheet>
  );
}

EnterEthAddressBottomSheet.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  ethereumAddress: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
};

export default EnterEthAddressBottomSheet;
