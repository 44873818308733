import React, { useState } from "react";
import characterCircle from "../../../assets/images/characterCircle.png";
import arrow from "../../../assets/images/arrow.png";
import arrowPressed from "../../../assets/images/arrowPressed.png";
import namePlate from "../../../assets/images/namePlate.png";
import "../../../styles/CharacterDisplay.css";
import useWindowSize from "../../../utils/WindowSize";

function CharacterDisplay({
  character,
  onNextCharacterPressed,
  onPrevCharacterPressed,
}) {
  const [leftArrowPressed, setLeftArrowPressed] = useState(false);
  const [rightArrowPressed, setRightArrowPressed] = useState(false);

  const { width } = useWindowSize();

  const handleLeftArrowMouseDown = () => setLeftArrowPressed(true);
  const handleLeftArrowMouseUp = () => setLeftArrowPressed(false);
  const handleRightArrowMouseDown = () => setRightArrowPressed(true);
  const handleRightArrowMouseUp = () => setRightArrowPressed(false);

  const calculateFontSize = (name) => {
    const length = name.length;
    if (length > 14) {
      if (width < 310) {
        return 17;
      }
      if (width < 350) {
        return 19;
      }
      return 21;
    }
    return 25.5;
  };

  return (
    <div className="character-display">
      <div className="character-container">
        <div
          className="name-plate"
          style={{ backgroundImage: `url(${namePlate})` }}
        >
          <div
            className="character-name"
            style={{ fontSize: calculateFontSize(character.name) }}
          >
            {character.name.toUpperCase()}
          </div>
        </div>
        <div className="circle-character-container">
          <button
            className={`nav-arrow-button left`}
            onClick={onPrevCharacterPressed}
            onTouchStart={handleLeftArrowMouseDown}
            onTouchEnd={handleLeftArrowMouseUp}
            onMouseDown={handleLeftArrowMouseDown}
            onMouseUp={handleLeftArrowMouseUp}
            onMouseLeave={handleLeftArrowMouseUp}
          >
            <img
              src={leftArrowPressed ? arrowPressed : arrow}
              alt="Previous"
              className={`nav-arrow`}
            />
          </button>
          <img src={characterCircle} alt="" className={`character-circle`} />
          <img
            src={character.image}
            alt={character.name}
            className={`character`}
          />
          <button
            className={`nav-arrow-button right`}
            onClick={onNextCharacterPressed}
            onTouchStart={handleRightArrowMouseDown}
            onTouchEnd={handleRightArrowMouseUp}
            onMouseDown={handleRightArrowMouseDown}
            onMouseUp={handleRightArrowMouseUp}
            onMouseLeave={handleRightArrowMouseUp}
          >
            <img
              src={rightArrowPressed ? arrowPressed : arrow}
              alt="Next"
              className={`nav-arrow`}
            />
          </button>
        </div>
      </div>
    </div>
  );
}

export default CharacterDisplay;
